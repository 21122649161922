const hideInput = form => {
  if (!form) return;

  const hiddenInput = form.querySelector('[id*="_HiddenInput_"]');
  if (!hiddenInput) return;

  hiddenInput.style.display = 'none';
};

const getSelection = form => {
  if (!form) return;

  const dropDownBox = form.querySelector('[id*="_RequestType_"]');
  if (!dropDownBox) return;

  dropDownBox.addEventListener('change', e => {
    const hiddenInput = form.querySelector('[id*="_HiddenInput_"]');
    if (!hiddenInput) return;
    hiddenInput.value = e.target.querySelector('option:checked').innerText;
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const forms = [
    document.querySelector('[id|="form-VBContactCustomerCare"]'),
    document.querySelector('[id|="form-VBContactCustomerCareNY"]')
  ].filter(Boolean);

  forms.forEach(form => {
    hideInput(form);
    getSelection(form);
  });
});

const observer = new MutationObserver(mutations => {
  const formInstances = mutations.filter(mutation => {
    const nodes = [...mutation.addedNodes.values()];

    return nodes.some(
      node =>
        node instanceof Element &&
        (node.id.includes('VBContactCustomerCare') || node.id.includes('VBContactCustomerCareNY'))
    );
  });

  if (formInstances.length) {
    const forms = [
      document.querySelector('[id|="form-VBContactCustomerCare"]'),
      document.querySelector('[id|="form-VBContactCustomerCareNY"]')
    ].filter(Boolean);

    forms.forEach(form => {
      hideInput(form);
      getSelection(form);
    });
  }
});

observer.observe(document.documentElement, { childList: true, subtree: true });
